<template>
    <nav-header></nav-header>
    <nav-sidebar></nav-sidebar>
    <div class="content-box" :class="{ 'content-collapse': collapse }">
       <div class="content">
            <router-view/>
       </div>
    </div>
</template>

<script>
import navHeader from "@/components/navHeader/NavHeader"
import navSidebar from "@/components/navSidebar/NavSidebar"
export default {
  name: 'Home',
  components: {
    navHeader,
    navSidebar
  },
  computed: {
    collapse() {
      return this.$store.state.collapse;
    }
  }
}
</script>

