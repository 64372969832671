export default {
    data: {
        public: {
            select: '選擇',
            viewMore: '查看更多',
            publish: '發佈',
            share: '分享',
            duplicate: '複製',
            update: '更新',
            confirm: '確認',
            cancel: '取消',
            publishAgain: '再次發布',
            downloadPdf: '下載PDF',
            printQRcode: '列印二維碼',
            printeCert: '列印證書',
            emailLink: '分享鏈接',
            exportExcel: '導出Excel',
            back: '返回',
            reset: '重設',
            save: '保存',
            remove: '刪除',
            delete: '删除',
            edit: '編輯',
            submit: '提交',
            action: '行動',
            view: '檢視',
            topUp: '增值',
            best: '最佳格式',
            sex: {
                male: '男',
                female: '女'
            },
            orderCompleted: '付款已完成',
            success: '成功',
            error: '失敗',
            agreeDisclaimer: '同意上述免責聲明',
            selectExpiredInfo: '選擇 "過期" 證書狀況並不能修改',
        },
        status: {
            status: '狀況',
            valid: '有效',
            expired: '過期',
            cancel: '取消',
            change: '更改',
            amended: '修訂',
            remark: '備註',
            isStatusDesc: '請注意: 一旦更改有效證書狀態, 無法再重新選擇'
        },
        finalStatus:{
            requested: '發起',
            deleted: '無效',
            approved: '生效',
            accepted: '接收'
        },
        approvalStatus:{
            pending: '有待等候',
            approved: '成功批核',
            disapproved: '不批核'
        },
        role: {
            Manager: '經理',
            Officer: '職員',
            SuperAdmin: '行政總管'
        },
        print: {
            title: '列印',
            printPreview: '列印預覽',
            certTitle: '證書名稱'
        },
        menu:{
            dashboard: '儀錶板',
            ecert: '證書',
            ecertChild: {
                list: '管理證書',
                group: '證書組別',
                approval: '證書批核'
            },
            account: '賬戶',
            accountChild: {
                user: '行政用戶',
                authority: '分行管理'
            },
            credits: '餘額',
            creditsChild: {
                credits: '餘額管理',
                topUp: '積分增值'
            },
            notification: '通知'
        },
        header:{
            searchEcert: '搜尋證書',
            createNewEcert: '創建新電子證書',
            setting: '機構設定',
            download: '下載用戶手冊',
            logOut: '退出登錄'
        },
        chart: {
            branchIssueReport: '分校發行證書統計',
            bestIssueCertificate: '十大最多發行證書',
            yearlyIssues: '年度發行證書（本年）',
            numberIssues: '發行證書數目',
        },
        login:{
            signIn: '登入',
            newUser: '新用戶',
            createAccount: '新建用戶',
            userName: '用戶名稱',
            password: '密碼',
            keepSigned: '保持登錄狀態',
            forgetPassword: '忘記密碼',
            contact: '請電郵cs&#64;ecert.app 聯繫我們的團隊'
        },
        register:{
            registrationSystem: '組織和機構申請使用表格',
            organizationInformation: '組織資料',
            eCertOrganizationSetting: '證書機構設置',
            organizationName: '機構名稱',
            organizationAddress: '機構地址',
            country: '國家',
            contactNumber: '聯絡電話',
            email: '電子郵件',
            website: '機構網站',
            organisationType: '組織類別',
            oneContactPerson: '第一聯絡人',
            twoContactPerson: '第二聯絡人',
            organizationLogo: '機構商標',
            campusPicture: '環境照片',
            uploadPicture: '上傳照片',
            registrations: '機構註冊/商業登記/許可文件',
            addFile: '添加文件',
            about: '關於我們',
            branchInformation: '總部將計入第一個分支機構（必須填寫）',
            branch: '分行',
            branchName: '分行名稱',
            branchAddress: '分行地址',
            addBranch: '添加分行',
            submit: '提交 &#124; 更新'
        },
        dashboard: {
            remainingCredits: '剩餘積分',
            issuedPresent: '可發證書',
            pendingRequests: '等候審批',
            buyCreditsIssues: '購買積分發證書',
            topUp: '增值',
            days: '7天',
            month: '一個月',
            year: '一年',
            exportExcel: '導出Excel',
            userNumber: '累積用戶',
            issuedNumber: '已發出證書總數量',
            usedCredit: '累積使用積分',
            viewNumber: '查看eCert數次',
            pendingApplications: '等候申請',
            viewAllApplications: '查看更多申請者',
            notifications: '通知',
            recentIssuedCerts: '最近發出的證書',
            viewAllIssuedCerts: '查看所有已頒發的證書'
        },
        eCert: {
            branchName: '分行名稱',
            remainingIssues: '其餘可發證書',
            eCertTopic: '證書主題',
            createTime: '創建時間',
            completedApplication: '完成申請',
            duplicateDesc1: '“複製”證書檔案後, 不可刪除！ ',
            duplicateDesc2: '您可以更改編輯文件內的內容',
            requesteCertQRcode: '通過二維碼申請電子證書',
            scanQRCode: '在CertApp中掃描二維碼獲取證書',
            certTitle: '證書名稱',
            printQRCode: '下載二維碼',
            copyLink: '複製鏈接以通過eCertApp領取電子證書',
            copyLinkBtn: '複製鏈接'
        },
        template: {
            certificateDetail: '證書明細',
            eCertTopic: '證書名稱',
            group: '組別',
            eCertCatagory: '證書類別',
            createNewGroupHere: '在此處創建新組',
            approveMember: '預先批核',
            searchUserEmail: '使用電郵搜索申請者',
            uploadSpreadsheet: '上載表格',
            createeCert: '設計電子證書',
            downloadDesign: '下載設計樣板',
            certificateContent: '證書內容',
            uploadDesign: '上載您的設計',
            uploadLeftLogo: '上載左邊商標',
            uploadMiddleLogo: '上載中間商標',
            defaultBackground: '選擇系統的設計背景',
            left: '左',
            center: '中',
            right: '右',
            certificateCourse: '輸入和證書相關的文本',
            certificateName: '證書名稱',
            certificateSubTitle: '此證書榮幸地頒發給',
            showPrintedName: '顯示列印名稱',
            showDateBirth: '顯示出生日期',
            showNationality: '顯示地區國籍',
            certificateDescription1: '這是一個示例文本。在此處輸入您想要的文本。這是一個示例文本。在此處輸入您想要的文本。頒發日期',
            eCertIssueDate: '證書發出日期（系統列印在證書上）',
            certificateDescription2: '這是一個示例文本。在此處輸入您想要的文本。截止日期',
            eCertExpiryDate: '證書截止日期（如有必要可選用）',
            certificateDescription3: '這是一個示例文本。在此處輸入您想要的文本。這是一個示例文本。在此處輸入您想要的文本。',
            leftSign: '左邊簽名/蓋章',
            centerSign: '中間簽名/蓋章',
            rightSign: '右邊簽名/蓋章',
            name: '姓名',
            title: '職位',
            organization: '組織/公司名稱',
            previewPrint: '預覽 &#124; 列印',
            numberIssuesRemainBranch: '分行可發佈的證書合共',
            issues: '份',
            preApprovedeCert: '"通過電郵" 預先核准的證書',
            acceptPublicApplicant: '"通過二維碼和鏈結" 接受公眾申請人',
            issueeCertsNow: '合共發佈證書',
            publishText1: '發布前！ 請使用"預覽/打印"功能, 確認您的設計和文本',
            publishText2: '出版後! 剩餘發布數量和積分不能退還或退款。',
            confirmIssuePopup: '確認發布彈窗',
            ConfirmIssue: '確認發布',
            certsNow: '份證書嗎',
            table: {
                photo: '照片',
                userName: '名稱',
                eMail: '郵件',
                actions: '作用',
                searchUserEmail: '以電郵搜尋用戶'
            }
        },
        eCertDetail: {
            topic: '主題',
            group: '組別',
            createTime: '創建時間',
            pendingApplication: '等候申請',
            approvedApplication: '批准申請',
            eCertIssueDate: '籤發日期',
            totalNumberIssues: '總發數量',
            scanQRCode: '通過eCertApp掃描二維碼獲取證書',
            eCertTitle: '證書名稱',
            table: {
                photo: '照片',
                applicantName: '申請者姓名',
                eCertNumber: '證書編號',
                issueDate: '簽發日期',
                campusBranch: '校園/分行',
                approval: '批核',
                approvedTime: '批核時間',
                approvedAccount: '批核賬戶'
            }
        },
        group: {
            createNewGroup: '創建新組別',
            isDeleteText: '決定要刪除嗎',
            eCertGroup: '證書組別',
            groupName: '組別名稱',
            enterGroupName: '輸入組別名稱',
            groupNumber: '組別號碼',
            enterGroupNumber: '輸入組別號碼',
            table: {
                groupNumber: '組別號碼',
                groupName: '組別名稱',
                createDate: '創建日期',
                campusBranch: '學校 / 分行',
                handleAccount: '行政組別',
                name: '行政名稱',
                designs: '設計',
                approvedCerts: '已批證書/批核證書',
                action: '行動'
            }
        },
        approval: {
            waitingApprove: '等候批核',
            table:{
                photo: '照片',
                applicantName: '申請者名稱',
                eCertNumber: '證書號碼',
                issueDate: '發出日期',
                campusBranch: '學校/分行',
                requestedTime: '申請時間',
                certTopic: '證書標題',
                userName: '申請人'
            }
        },
        account: {
            addUse: '添加設置用戶',
            userInfo: '用戶信息',
            id: 'ID',
            username: '名稱',
            name: '進入名稱',
            icon: '商標',
            password: '密碼',
            role: '職位',
            branchName: '分行名稱',
            createTime: '創立時間',
            updateTime: '更新時間',
            table: {
                photo: '照片',
                id: 'ID',
                userName: '設置用戶',
                name: '名稱',
                role: '職位',
                branchName: '分行名稱'
            }
        },
        branch: {
            addBranch: '添加分行',
            isDeleteText: '決定要刪除嗎',
            branchInformation: '分行情況',
            table: {
                BranchName: '分行名稱',
                BranchAddress: '分行地址',
                Country: '國家地區',
                ContactNumber: '聯絡電話',
                ContactPerson: '聯繫人',
                eMail: '電郵'
            }
        },
        credits: {
            organizationName: '組織名稱',
            organizationId: '組織編號',
            remainingCredits: '剩餘積分',
            issue: '張證書',
            issues: '發行',
            credits: '積分',
            accounts: '賬戶',
            assignCredits: '分配發行',
            issuesDisturbution: '發行分配',
            eCertIssueReport: '分行發行報告',
            pricing: '價錢',
            price: '價錢',
            usd: '美金',
            paymentHistory: '付款記錄',
            creditRedeemHistory: '積分使用記錄',
            invoice: '賬單',
            proceedPayment: '繼續付款',
            paymentStatus: {
                waiting: '等待',
                successful: '批准',
                failure: '拒絕'
            },
            table:{
                date: '日期',
                time: '時間',
                package: '套裝',
                amount: '數量',
                status: '狀況',
                branchName: '分行名稱',
                account: '賬戶',
                certName: '證書名稱',
                creditRedeemed: '積分兌換',
                publishedCerts: '出版證書'
            }
        },
        invoice: {
            invoice: '發票 &#124; 單據',
            orderDate: '訂購日期',
            invoiceNumber: '發票編號',
            paymentMethod: '付款方法',
            name: '姓名',
            organisationAddress: '組織',
            email: '電郵',
            item: '項目',
            total: '總計（美元）',
            subtotal: '小計',
            tax: '稅',
            grandTotal: '累計',
            questions: '有疑问',
            emailUsAt: '請給我們發電郵'
        },
        notification: {
            title: '通知',
            event: '事項',
            time: '時間',
            details: '明細'
        },
        issuedCerts: {
            table: {
                photo: '照片',
                applicantName: '申請者姓名',
                eCertNumber: '證書編號',
                certTopic: '證書主題',
                certIssueDate: '籤發日期',
                campusBranch: '校園/分行',
                status: '證書狀況',
                approvedTime: '批核時間',
                approvedBy: '批核者'
            }
        },
        applicationDetail: {
            title: '申請人詳情',
            topic: '證書主題',
            group: '證書組別',
            eCertNo: '證書編號',
            timeRequested: '申請時間',
            approvalTime: '批核時間',
            previewEcert: '查看證書',
            uploadDocument: '上傳文檔',
            applicantId: '申請人編號',
            informationClaimed: '要求驗證的信息',
            firstName: '名',
            lastName: '姓',
            namePrinted: '印在證書上的姓名',
            email: '郵箱',
            mobile: '手機',
            sex: '性別',
            nationality: '國籍',
            dateBirth: '出生日期',
            address: '地址',
            action: '證書狀態',
            scanQRCode: '通過eCertApp掃描二維碼獲取eCert',
            eCertTitle: '電子證書名稱'
        }
    },
    el:{
        pagination: {
            goto: '跳轉',
            pagesize: '條/頁',
            total: '共 {total} 條',
            pageClassifier: '',
        },
        popconfirm: {
            confirmButtonText: '是',
            cancelButtonText: '否',
        },
        select: {
            loading: '加載中',
            noMatch: '無匹配資料',
            noData: '無資料',
            placeholder: '請選擇',
        },
        datepicker: {
            now: '現在',
            today: '今天',
            cancel: '取消',
            clear: '清空',
            confirm: '確認',
            selectDate: '選擇日期',
            selectTime: '選擇時間',
            startDate: '開始日期',
            startTime: '開始時間',
            endDate: '結束日期',
            endTime: '結束時間',
            prevYear: '前一年',
            nextYear: '後一年',
            prevMonth: '上個月',
            nextMonth: '下個月',
            year: '年',
            month1: '1 月',
            month2: '2 月',
            month3: '3 月',
            month4: '4 月',
            month5: '5 月',
            month6: '6 月',
            month7: '7 月',
            month8: '8 月',
            month9: '9 月',
            month10: '10 月',
            month11: '11 月',
            month12: '12 月',
            weeks: {
                sun: '日',
                mon: '一',
                tue: '二',
                wed: '三',
                thu: '四',
                fri: '五',
                sat: '六',
            },
            months: {
                jan: '一月',
                feb: '二月',
                mar: '三月',
                apr: '四月',
                may: '五月',
                jun: '六月',
                jul: '七月',
                aug: '八月',
                sep: '九月',
                oct: '十月',
                nov: '十一月',
                dec: '十二月',
            },
            monthNaN: ''
        },
        table: {
            emptyText: '暫無資料',
            confirmFilter: '篩選',
            resetFilter: '重置',
            clearFilter: '全部',
            sumText: 'Sum',
        },
        upload: {
            deleteTip: '按 delete 鍵可刪除',
            delete: '刪除',
            preview: '查看圖片',
            continue: '繼續上傳',
        }
    }
  }