import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    redirect: '/dashboard'
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        meta: {
          title: 'Dashboard'
        },
        component: () => import('../views/dashboard/Dashboard.vue')
      },
      {
        path: '/ecert/list',
        name: 'ecertList',
        meta: {
          title: 'eCert List'
        },
        component: () => import('../views/eCert/List.vue')
      },
      {
        path: '/ecert/group',
        name: 'ecertGroup',
        meta: {
          title: 'eCert Group',
          permission: 'manager'
        },
        component: () => import('../views/eCert/Group.vue')
      },
      {
        path: '/ecert/approval',
        name: 'ecertApproval',
        meta: {
          title: 'eCert Approval'
        },
        component: () => import('../views/eCert/Approval.vue')
      },
      {
        path: '/ecert/edit',
        name: 'ecertEdit',
        meta: {
          title: 'eCert Add'
        },
        component: () => import('../views/eCert/Edit.vue')
      },
      {
        path: '/ecert/edit/:id',
        name: 'ecertAdd',
        meta: {
          title: 'eCert Edit'
        },
        component: () => import('../views/eCert/Edit.vue')
      },
      {
        path: '/ecert/print',
        name: 'ecertPrint',
        meta: {
          title: 'eCert Print'
        },
        component: () => import('../views/eCert/Print.vue')
      },
      {
        path: '/ecert/qrcode-print',
        name: 'qrcodePrint',
        meta: {
          title: 'Qrcode Print'
        },
        component: () => import('../views/eCert/QrcodePrint.vue')
      },
      {
        path: '/ecert/detail/:id',
        name: 'ecertDetail',
        meta: {
          title: 'eCert Detail'
        },
        component: () => import('../views/eCert/Detail.vue')
      },
      {
        path: '/ecert/applicant-detail/:id',
        name: 'applicantDetail',
        meta: {
          title: 'Applicant Detail'
        },
        component: () => import('../views/eCert/ApplicantDetail.vue')
      },
      {
        path: '/ecert/application-detail/:id',
        name: 'applicationDetail',
        meta: {
          title: 'Application Detail'
        },
        component: () => import('../views/eCert/ApplicationDetail.vue')
      },
      {
        path: '/ecert/issued-certs',
        name: 'issuedCerts',
        meta: {
          title: 'Issued Certs'
        },
        component: () => import('../views/eCert/IssuedCerts.vue')
      },
      {
        path: '/credits/main',
        name: 'creditsMain',
        meta: {
          title: 'Credits Main',
          permission: 'manager'
        },
        component: () => import('../views/credits/Main.vue')
      },
      {
        path: '/credits/pricing',
        name: 'creditsPricing',
        meta: {
          title: 'Credits Pricing',
          permission: 'admin'
        },
        component: () => import('../views/credits/Pricing.vue')
      },
      {
        path: '/credits/invoice/:id',
        name: 'creditsInvoice',
        meta: {
          title: 'Credits Invoice',
          permission: 'manager'
        },
        component: () => import('../views/credits/Invoice.vue')
      },
      {
        path: '/account/user',
        name: 'accountUser',
        meta: {
          title: 'Account User',
          permission: 'manager'
        },
        component: () => import('../views/account/User.vue')
      },
      {
        path: '/account/authority',
        name: 'accountAuthority',
        meta: {
          title: 'Account Authority',
          permission: 'admin'
        },
        component: () => import('../views/account/Authority.vue')
      },
      {
        path: '/account/organization',
        name: 'accountOrganization',
        meta: {
          title: 'Account organization'
        },
        component: () => import('../views/account/Organization.vue')
      },
      {
        path: '/notification/list',
        name: 'notificationList',
        meta: {
          title: 'Notification List'
        },
        component: () => import('../views/notification/List.vue')
      }
    ]
  },
  {
    path: "/login",
    name: "Login",
    meta: {
        title: 'Login'
    },
    component: () => import ("../views/login/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    meta: {
        title: 'Register'
    },
    component: () => import ("../views/login/Register.vue")
  },
  {
    path: "/forget",
    name: "Forget",
    meta: {
        title: 'Forget'
    },
    component: () => import ("../views/login/Forget.vue")
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  let role;
  document.title = `${to.meta.title}`;
  if(JSON.parse(localStorage.getItem("userInfo"))){
    role = JSON.parse(localStorage.getItem("userInfo")).info.role;
  }
  if (!role && to.path !== '/login' && !role && to.path !== '/register' && to.path !== '/forget') {
      next('/login');
  } else if (to.meta.permission) {
      if(role === 'admin'){
        next();
      } else if(to.meta.permission === role){
        next();
      } else{
        next('/403');
      }
  } else {
      next();
  }
});

export default router
