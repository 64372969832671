import { createI18n } from 'vue-i18n/index'
// import locale from 'element-plus/lib/locale/lang/zh-cn'
import data from './index'
const language = (
  localStorage.getItem("language") || "en"
).toLowerCase();
const i18n = createI18n({
  fallbackLocale: 'en',
  globalInjection:true,
  legacy: false, // you must specify 'legacy: false' option
  locale: language.split("-")[0] || "en",
  messages: data,
});

export default i18n