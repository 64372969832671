import axios from 'axios';
import { nextTick } from 'vue';
import { ElMessage,ElLoading  } from 'element-plus';
import _ from 'lodash';

//请求超时时间
axios.defaults.timeout = 10000;

//默认请求地址
axios.defaults.baseURL ='https://api.ecert.app/org-api';

// Loading 的配置项
let options;
let loadingInstance; //loading 实例
let needLoadingRequestCount = 0; //当前正在请求的数量
function showLoading() {
    let main = document.querySelector('#app')        //获取dom节点
    if (main) {
       if (needLoadingRequestCount === 0 && !loadingInstance) {
          loadingInstance = ElLoading.service(options);
       }
       needLoadingRequestCount++;
    }
    // console.log('111111111',needLoadingRequestCount)
  }
  function closeLoading() {
    nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
       needLoadingRequestCount--;
       needLoadingRequestCount = Math.max(needLoadingRequestCount, 0); // 保证大于等于0
       if (needLoadingRequestCount === 0) {
          if (loadingInstance) {
             hideLoading()
          }
       }
    });
  }
  //防抖
  var hideLoading = _.debounce(() => {
    loadingInstance.close();
    loadingInstance = null;
  },10);

//http request 請求拦截器
axios.interceptors.request.use(
    config => {
        let token;
        let lang = localStorage.getItem("language");
        if(localStorage.getItem('userInfo')){
            let jason_token = JSON.parse(localStorage.getItem('userInfo'))
            token = jason_token.access_token;
        }
        if(token){
        config.headers['Authorization'] = 'bearer ' + token
        }
        if(config.data){
            if(typeof config.data.image === 'object'){
                config.headers['Content-Type'] = 'multipart/form-data'
            }
        }
        if(lang === 'cn'){
            config.headers['X-Locale'] = 'zh-CN'
        } else if (lang === 'en'){
            config.headers['X-Locale'] = 'en'
        } else {
            config.headers['X-Locale'] = 'zh-HK'
        }
        return config;
    },
    error => {
     return Promise.reject(err);
    }
);

//http response 響應拦截器
axios.interceptors.response.use(
    response => {
     return response;
    },
    error => {
      if(error.response.status === 401 || error.response.data.error_code === 40100000){
         localStorage.removeItem('userInfo');
         window.location.href = '/login'
      }
      let errorInfo
      if(error.response.data.errors){
        errorInfo = error.response.data.errors;
      } else {
        errorInfo = { message: error.response.data.message }
      }
      
      ElMessage.error(errorInfo[Object.keys(errorInfo)[0]]);
     return Promise.reject(error)
    }
)

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get(url,params={}){
    showLoading();
    return new Promise((resolve,reject) => {
     axios.get(url,{
      params:params.apiData
     })
     .then(response => {
        closeLoading();
        resolve(response.data);
     })
     .catch(err => {
        closeLoading();
        reject(err)
     })
    })
}
   
   
/**
* 封装post请求
* @param url
* @param data
* @returns {Promise}
*/
export function post(url,data = {}){
    showLoading();
    return new Promise((resolve,reject) => {
    axios.post(url, data.apiData, { emulateJSON: true})
    .then(response => {
        closeLoading();
        resolve(response.data);
    },err => {
        closeLoading();
        reject(err)
    })
    })
}
   
/**
* 封装patch请求
* @param url
* @param data
* @returns {Promise}
*/
export function patch(url,data = {}){
    showLoading();
    return new Promise((resolve,reject) => {
        axios.patch(url,data.apiData)
        .then(response => {
            closeLoading();
            resolve(response.data);
        },err => {
            closeLoading();
            reject(err)
        })
    })
}
   
/**
* 封装put请求
* @param url
* @param data
* @returns {Promise}
*/
export function put(url,data = {}){
    showLoading();
    return new Promise((resolve,reject) => {
        axios.put(url,data.apiData)
        .then(response => {
            closeLoading();
            resolve(response.data);
        },err => {
            closeLoading();;
            reject(err)
        })
    })
}

/**
* 封装delete请求
* @param url
* @param data
* @returns {Promise}
*/
export function del(url,data = {}){
    return new Promise((resolve,reject) => {
        axios.delete(url,data)
        .then(response => {
            resolve(response.data);
        },err => {
            reject(err)
        })
    })
}