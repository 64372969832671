<template>

  <router-view/>

</template>

<script>

</script>


<style>
* {
    margin: 0;
    padding: 0;
}

body, textarea{
  font-family: "Roboto",sans-serif;
}
.el-card{
  color: #343a40;
}
html,
body,
#app,
.wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.el-button--green.is-active,
.el-button--green:active {
  background: #03CAD5;
  border-color: #03CAD5;
  color: #333;
}

.el-button--green:focus,
.el-button--green:hover {
  background: #21d2db;
  border-color: #21d2db;
  color: #333;
}

.el-button--green {
  color: #333;
  background-color: #03CAD5;
  border-color: #03CAD5;
}
/* .content > div{
  min-width: 1620px;
} */
a {
    text-decoration: none
}
.el-col-25{
  width: 20%;
}
.el-table{
  color: #868e96;
}
.el-table th{
  color: #343a40;
}
.card-header{
  color: #224486;
  font-weight: 600;
  font-size: 21px;
  position: relative;
}
.card-header .el-button{
  position: absolute;
  min-height: 27px;
  right: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.content-box {
    position: absolute;
    left: 250px;
    right: 0;
    top: 70px;
    bottom: 0;
    padding-bottom: 30px;
    -webkit-transition: left .3s ease-in-out;
    transition: left .3s ease-in-out;
    background: #f0f0f0;
}

.content {
    width: auto;
    height: 100%;
    padding: 20px;
    overflow-y: scroll;
    box-sizing: border-box;
}

.content-collapse {
    left: 65px;
}

.container {
    padding: 30px;
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.mb-no{
  margin-bottom: 0px !important;
}
.mb-10{
  margin-bottom: 10px;
}
.mb-15{
  margin-bottom: 15px;
}
.mb-20{
  margin-bottom: 20px;
}
.mb-25{
  margin-bottom: 25px;
}
.mb-30{
  margin-bottom: 30px;
}

.mt-10{
  margin-top: 10px;
}
.mt-15{
  margin-top: 15px;
}
.mt-20{
  margin-top: 20px;
}
.mt-25{
  margin-top: 25px;
}
.mt-30{
  margin-top: 30px;
}

.page-header{
    padding-bottom: 10px;
}
.table-user-photo{
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50%;
  display: table-cell;
  vertical-align: middle;
}

.el-table .cell{
  word-wrap: break-word;
  word-break: keep-all;
}
.text-flex{
    display: flex
}
.text-flex .name-left{
    text-align: right;
    padding: 0px 5px;
}
.text-flex .val-right{
    text-align: left;
    padding: 0px 5px;
}
</style>
