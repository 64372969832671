  
import { post,get,patch,put,del } from '../utlis/http'
/**
 * 这里统一处理项目中所有的 api 接口
 */

 // 图片上传
 export const uploadImage = params => post('/upload/image',params);
  // 文件上傳
  export const uploadFile = params => post('/upload/file',params);
 //获取国家&手机区号
 export const getCounties = params => get('/counties',params);
 // 获取证书分类
 export const getCertCategories = params => get('/cert-categories',params);
 // 分支积分和待审核证书请求数
 export const branchCredit = params => get('/branch/credit',params);
 // 分支通知列表
 export const branchNotifications = params => get('branch-notifications',params);
  // 分支通知未读数
  export const notificationsUnreadCount = params => get('/branch-notifications/unread-count',params);
  // 打印
  export const simpleCerts = params => get('template-certs/'+params.apiData.id+'/simple-certs',params);

// 基础数据
export const getBase = params => get('/base',params);
// 机构注册协议
export const getProtocols = params => get('/protocols/'+params.apiData.protocol,params);
// 所在分支信息
export const userBranch = params => get('/user/branch',params);

 // 用户登陆
export const userLogin = params => post('/auth/login',params);
// 用戶登出
export const userLogut = params => post('/auth/logout',params);
// 忘记密码
export const passwordEmail = params => post('/auth/password/email',params);
// 重置密码
export const passwordReset = params => post('/auth/password/reset',params);

//機構註冊
export const userRegister = params => post('/apply',params);


// 機構信息
export const organizationInfo = params => get('/organization',params);
// 機構類型
export const organizationType = params => get('/organization-types',params);
// 修改機構信息
export const editOrganization = params => patch('/organization',params);


// 证书年度统计
export const yearCert = params => get('/stats/year-cert',params);

// 获取剩余积分
export const getCredit = params => get('/credit',params);
// 分配積分
export const credisAssign = params => put('/credit-assign',params);

// 证书统计
export const statsCert = params => get('/stats/cert',params);
// 证书发行
export const certsTemplateIssue = params => post('/template-certs/'+params.apiData.id+'/issue',params);
// 批量发行任务详情
export const batchIssues = params => get('/batch-issues/'+params.apiData.id,params);

// 模板证书列表
export const templateCertsList = params => get('/template-certs',params);
// 创建模板证书
export const newCertsTemplate = params => post('/template-certs',params);
// 模板证书详情
export const certsTemplateDetails = params => get('template-certs/'+params.apiData.id,params);
// 模板证书修改
export const certsTemplateEdit = params => patch('/template-certs/'+params.apiData.id,params);
// 模板複製
export const certsTemplateDuplicate = params => post('/template-certs/'+params.apiData.id+'/duplicate',params);
// 模板修改狀態
export const certsStatus = params => put('/template-certs/'+params.apiData.id+'/status',params);
//證書下載
export const certsDownload = params => get('/template-certs/'+params.apiData.id+'/certs-download',params);


// 证书列表
export const certsList = params => get('/certs',params);
// 证书详情
export const certsDetails = params => get('/certs/'+params.apiData.id,params);
// 證書狀態修改
export const certsStatusReport = params => patch('/certs/'+params.apiData.id,params);

// 证书分组列表
export const certGroups = params => get('/cert-groups',params);
// 创建证书分组
export const certGroupsPost = params => post('/cert-groups',params);
// 修改证书分组
export const certGroupsPatch = params => patch('/cert-groups/'+params.apiData.id,params);
// 删除证书分组
export const certGroupsDel = params => del('/cert-groups/'+params.apiData.id,params);

// 用户列表
export const userList = params => get('/users',params);
// 添加用户
export const addUser = params => post('/users',params);
// 修改用户
export const editUser = params => patch('/users/'+params.apiData.id,params);
// 删除用户
export const delUser = params => del('/users/'+params.apiData.id,params);

// 分支列表
export const branchesList = params => get('/branches',params);
// 创建分支
export const addBranches = params => post('/branches',params);
// 修改分支
export const editBranches = params => patch('/branches/'+params.apiData.id,params);
// 删除分支
export const delBranches = params => del('/branches/'+params.apiData.id,params);

// 会员列表
export const membersList = params => get('/members',params);
// 会员列表
export const membersCertApplies = params => get('/cert-applies',params);
// 導出会员
export const membersExport = params => get('/certs-export',params);
// 導入会员
export const memberImportSearch = params => post('/member-import-search',params);
// 會員證書審核
export const certAppliesAudit = params => put('/cert-applies/'+params.apiData.id+'/audit',params);

// 充值列表
export const pricingList = params => get('/recharge-packages',params);
// 充值詳情
export const pricingDetail = params => get('/recharges/'+params.apiData.id,params);
// 購買
export const pricingBuy = params => post('/recharge-packages/'+params.apiData.id+'/buy',params);
// 分支发行记录
export const branchCreditLogs = params => get('/branch-credit-logs',params);
// 充值记录
export const rechargesList = params => get('/recharges',params);
