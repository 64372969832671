export default {
    data: {
      public: {
        select: '选择',
        viewMore: '查看更多',
        publish: '发布',
        share: '分享',
        duplicate: '复制',
        update: '更新',
        confirm: '确定',
        cancel: '取消',
        publishAgain: '再次发布',
        downloadPdf: '下载PDF',
        printQRcode: '打印二维码',
        printeCert: '打印证书',
        emailLink: '分享链接',
        exportExcel: '导出Excel',
        back: '返回',
        reset: '重设',
        save: '保存',
        remove: '删除',
        delete: '删除',
        edit: '编辑',
        submit: '提交',
        action: '行动',
        view: '检视',
        topUp: '增值',
        best: '最佳格式',
        sex: {
            male: '男',
            female: '女'
        },
        orderCompleted: '付款已完成',
        success: '成功',
        error: '失败',
        agreeDisclaimer: '同意上述免责声明',
        selectExpiredInfo: '选择 "过期" 证书状况并不能修改',
      },
      status: {
        status: '状况',
        valid: '有效',
        expired: '过期',
        cancel: '取消',
        change: '更改',
        amended: '修订',
        remark: '备注',
        isStatusDesc: '请注意: 一旦更改有效证书状态, 无法再重新选择'
      },
      finalStatus:{
        requested: '发起',
        deleted: '无效',
        approved: '生效',
        accepted: '接收'
      },
      approvalStatus:{
        pending: '有待等候',
        approved: '成功批核',
        disapproved: '不批核'
      },
      role: {
          Manager: '经理',
          Officer: '职员',
          SuperAdmin: '行政总管'
      },
      print: {
        title: '打印',
        printPreview: '打印预览',
        certTitle: '证书名称'
      },
      menu:{
        dashboard: '仪表盘',
        ecert: '证书',
        ecertChild: {
            list: '管理证书',
            group: '证书组别',
            approval: '证书批核'
        },
        account: '账户',
        accountChild: {
            user: '行政用户',
            authority: '分行管理'
        },
        credits: '餘額',
        creditsChild: {
          credits: '餘額管理',
          topUp: '積分增值'
        },
        notification: '通知'
      },
      header:{
        searchEcert: '搜寻证书',
        createNewEcert: '创建新电子证书',
        setting: '机构设定',
        download: '下载用户手册',
        logOut: '退出登录'
      },
      chart: {
        branchIssueReport: '分校发行证书统计',
        bestIssueCertificate: '十大最多发行证书',
        yearlyIssues: '年度发行证书（本年）',
        numberIssues: '发行证书数目',
      },
      login:{
        signIn: '登入',
        newUser: '新用戶',
        createAccount: '新建用戶',
        userName: '用戶名称',
        password: '密码',
        keepSigned: '保持登录状态',
        forgetPassword: '忘记密码',
        contact: '请电邮cs&#64;ecert.app 联系我们的团队'
      },
      register:{
        registrationSystem: '组织和机构申请使用表格',
        organizationInformation: '组织资料',
        eCertOrganizationSetting: '证书机构设置',
        organizationName: '机构名称',
        organizationAddress: '机构地址',
        country: '国家',
        contactNumber: '联系电话',
        email: '电子邮件',
        website: '机构网站',
        organisationType: '组织类别',
        oneContactPerson: '第一联络人',
        twoContactPerson: '第二联络人',
        organizationLogo: '商标机构',
        campusPicture: '环境照片',
        uploadPicture: '上传照片',
        registrations: '机构注册/商业登记/许可文件',
        addFile: '添加文件',
        about: '关于我们',
        branchInformation: '总部将计入第一个分支机构（必须填写）',
        branch: '分行',
        branchName: '分行名称',
        branchAddress: '分行地址',
        addBranch: '添加分行',
        submit: '提交 &#124; 更新'
      },
      dashboard: {
          remainingCredits: '剩余积分',
          issuedPresent: '可发证书',
          pendingRequests: '等候审批',
          buyCreditsIssues: '购买积分发证书',
          topUp: '增值',
          days: '7天',
          month: '一个月',
          year: '一年',
          exportExcel: '导出Excel',
          userNumber: '累积用户',
          issuedNumber: '已发出证书总数量',
          usedCredit: '累积使用积分',
          viewNumber: '查看eCert数次',
          pendingApplications: '等候申请',
          viewAllApplications: '查看更多申请者',
          notifications: '通知',
          recentIssuedCerts: '最近发出的证书',
          viewAllIssuedCerts: '查看所有已颁发的证书'
      },
      eCert: {
          branchName: '分行名称',
          remainingIssues: '其余可发证书',
          eCertTopic: '证书主题',
          createTime: '创建时间',
          completedApplication: '完成申请',
          duplicateDesc1: '“复制”证书档案后, 不可删除！',
          duplicateDesc2: '您可以更改编辑文件内的内容',
          requesteCertQRcode: '通过二维码申请电子证书',
          scanQRCode: '在eCertApp中扫描二维码获取证书',
          certTitle: '证书名称',
          printQRCode: '下载二维码',
          copyLink: '复製链接以通过eCertApp领取电子证书',
          copyLinkBtn: '复制链接'
      },
      template: {
        certificateDetail: '证书明细',
        eCertTopic: '证书名称',
        group: '组别',
        eCertCatagory: '证书类别',
        createNewGroupHere: '在此处创建新组',
        approveMember: '预先批核',
        searchUserEmail: '使用电邮搜索申请者',
        uploadSpreadsheet: '上载表格',
        createeCert: '设计电子证书',
        downloadDesign: '下载设计样板',
        certificateContent: '证书内容',
        uploadDesign: '上载您的设计',
        uploadLeftLogo: '上载左边商标',
        uploadMiddleLogo: '上载中间商标',
        defaultBackground: '选择系统的设计背景',
        left: '左',
        center: '中',
        right: '右',
        certificateCourse: '输入和证书相关的文本',
        certificateName: '证书名称',
        certificateSubTitle: '此证书荣幸地颁发给',
        showPrintedName: '显示列印名称',
        showDateBirth: '显示出生日期',
        showNationality: '显示地区国籍',
        certificateDescription1: '这是一个示例文本。在此处输入您想要的文本。这是一个示例文本。在此处输入您想要的文本。颁发日期',
        eCertIssueDate: '证书发出日期（系统列印在证书上） ',
        certificateDescription2: '这是一个示例文本。在此处输入您想要的文本。截止日期',
        eCertExpiryDate: '证书截止日期（如有必要可选用）',
        certificateDescription3: '这是一个示例文本。在此处输入您想要的文本。这是一个示例文本。在此处输入您想要的文本。',
        leftSign: '左边签名/盖章',
        centerSign: '中间签名/盖章',
        rightSign: '右边签名/盖章',
        name: '姓名',
        title: '职位',
        organization: '组织/公司名称',
        previewPrint: '预览 &#124; 打印',
        numberIssuesRemainBranch: '分行可发布的证书合共',
        issues: '份',
        preApprovedeCert: '"通过电邮" 预先核准的证书',
        acceptPublicApplicant: '"通过二维码和链结" 接受公众申请人',
        issueeCertsNow: '合共发布证书',
        publishText1: '发布前！请使用"预览/打印"功能, 确认您的设计和文本。',
        publishText2: '出版后! 剩余发布数量和积分不能退还或退款。',
        confirmIssuePopup: '确认发布弹窗',
        ConfirmIssue: '确认发布',
        certsNow: '份证书吗',
        table: {
            photo: '照片',
            userName: '名称',
            eMail: '邮件',
            actions: '作用',
            searchUserEmail: '以电邮搜索用户'
        }
    },
      eCertDetail: {
          topic: '主题',
          group: '组别',
          createTime: '创建时间',
          pendingApplication: '等候申请',
          approvedApplication: '批准申请',
          eCertIssueDate: '签发日期',
          totalNumberIssues: '总发数量',
          scanQRCode: '通通过eCertApp扫描二维码获取证书',
          eCertTitle: '证书名称',
          table: {
              photo: '照片',
              applicantName: '申请者姓名',
              eCertNumber: '证书编号',
              issueDate: '签发日期',
              campusBranch: '校园/分行',
              approval: '批核',
              approvedTime: '批核时间',
              approvedAccount: '批核账户'
          }
      },
      group: {
          createNewGroup: '创建新组别',
          isDeleteText: '决定要删除吗',
          eCertGroup: '证书组别',
          groupName: '组别名称',
          enterGroupName: '输入组别名称',
          groupNumber: '组别号码',
          enterGroupNumber: '输入组别号码',
          table: {
              groupNumber: '组别号码',
              groupName: '组别名称',
              createDate: '创建日期',
              campusBranch: '学校 / 分行',
              handleAccount: '行政组别',
              name: '行政名称',
              designs: '设计',
              approvedCerts: '已批证书/批核证书',
              action: '行动'
          }
      },
      approval: {
          waitingApprove: '等候批核',
          table:{
              photo: '照片',
              applicantName: '申请者名称',
              eCertNumber: '证书号码',
              issueDate: '发出日期',
              campusBranch: '学校/分行',
              requestedTime: '申请时间',
              certTopic: '证书标题',
              userName: '申请人'
          }
      },
      account: {
          addUse: '添加设置用户',
          userInfo: '用户信息',
          id: 'ID',
          username: '姓名',
          name: '进入名称',
          icon: '商标',
          password: '密码',
          role: '职位',
          branchName: '分行名称',
          createTime: '创新时间',
          updateTime: '更新时间',
          table: {
              photo: '照片',
              id: 'ID',
              userName: '设置用户',
              name: '名称',
              role: '职位',
              branchName: '分行名称'
          }
      },
      branch: {
          addBranch: '添加分行',
          isDeleteText: '决定要删除吗',
          branchInformation: '分行情况',
          table: {
              BranchName: '分行名称',
              BranchAddress: '分行地址',
              Country: '国家地区',
              ContactNumber: '联系电话',
              ContactPerson: '联系人',
              eMail: '电邮'
          }
      },
      credits: {
        organizationName: '组织名称',
        organizationId: '组织编号',
        remainingCredits: '剩余积分',
        issue: '张证书',
        issues: '发行',
        credits: '积分',
        assignCredits: '分配发行',
        accounts: '账户',
        issuesDisturbution: '发行分配',
        eCertIssueReport: '分行发行报告',
        pricing: '价格',
        price: '价钱',
        usd: '美金',
        paymentHistory: '付款记录',
        creditRedeemHistory: '积分使用记录',
        invoice: '账单',
        proceedPayment: '继续付款',
        paymentStatus: {
            waiting: '等待',
            successful: '批准',
            failure: '拒绝'
        },
        table:{
            date: '日期',
            time: '时间',
            package: '套装',
            amount: '数量',
            status: '状况',
            branchName: '分行名称',
            account: '账户',
            certName: '证书名称',
            creditRedeemed: '积分兑换',
            publishedCerts: '出版证书'
        }
      },
      invoice: {
          invoice: '发票 &#124; 单据',
          orderDate: '订购日期',
          invoiceNumber: '发票编号',
          paymentMethod: '付款方法',
          name: '姓名',
          organisationAddress: '组织',
          email: '电邮',
          item: '项目',
          total: '总计（美元）',
          subtotal: '小计',
          tax: '税',
          grandTotal: '累计',
          questions: '有疑问',
          emailUsAt: '请给我们发电邮'
      },
      notification: {
          title: '通知',
          event: '事项',
          time: '时间',
          details: '明细'
      },
      issuedCerts: {
          table: {
              photo: '照片',
              applicantName: '申请者姓名',
              eCertNumber: '证书编号',
              certTopic: '证书主题',
              certIssueDate: '签发日期',
              campusBranch: '校园/分行',
              status: '证书状态',
              approvedTime: '批核时间',
              approvedBy: '批核者'
          }
      },
      applicationDetail: {
          title: '申请人详情',
          topic: '证书主題',
          group: '证书组别',
          eCertNo: '证书编号',
          timeRequested: '申请时间',
          approvalTime: '批核时间',
          previewEcert: '查看证书',
          uploadDocument: '上传文档',
          applicantId: '申请人编号',
          informationClaimed: '要求验证的信息',
          firstName: '名',
          lastName: '姓',
          namePrinted: '印在证书上的姓名',
          email: '邮箱',
          mobile: '手机',
          sex: '性别',
          nationality: '国籍',
          dateBirth: '出生日期',
          address: '地址',
          action: '证书状态',
          scanQRCode: '通过eCertApp扫描二维码获取eCert',
          eCertTitle: '电子证书名称'
      }
    },
    el:{
      pagination: {
          goto: '跳转',
          pagesize: '条/页',
          total: '共 {total} 条',
          pageClassifier: '',
      },
      popconfirm: {
          confirmButtonText: '是',
          cancelButtonText: '否',
      },
      select: {
        loading: '加载中',
        noMatch: '无匹配数据',
        noData: '无数据',
        placeholder: '请选择',
      },
      datepicker: {
        now: '此刻',
        today: '今天',
        cancel: '取消',
        clear: '清空',
        confirm: '确定',
        selectDate: '选择日期',
        selectTime: '选择时间',
        startDate: '开始日期',
        startTime: '开始时间',
        endDate: '结束日期',
        endTime: '结束时间',
        prevYear: '前一年',
        nextYear: '后一年',
        prevMonth: '上个月',
        nextMonth: '下个月',
        year: '年',
        month1: '1 月',
        month2: '2 月',
        month3: '3 月',
        month4: '4 月',
        month5: '5 月',
        month6: '6 月',
        month7: '7 月',
        month8: '8 月',
        month9: '9 月',
        month10: '10 月',
        month11: '11 月',
        month12: '12 月',
        weeks: {
            sun: '日',
            mon: '一',
            tue: '二',
            wed: '三',
            thu: '四',
            fri: '五',
            sat: '六',
        },
        months: {
            jan: '一月',
            feb: '二月',
            mar: '三月',
            apr: '四月',
            may: '五月',
            jun: '六月',
            jul: '七月',
            aug: '八月',
            sep: '九月',
            oct: '十月',
            nov: '十一月',
            dec: '十二月',
        },
        monthNaN: ''
      },
      table: {
          emptyText: '暂无数据',
          confirmFilter: '筛选',
          resetFilter: '重置',
          clearFilter: '全部',
          sumText: '合计',
      },
      upload: {
          deleteTip: '按 delete 键可删除',
          delete: '删除',
          preview: '查看图片',
          continue: '继续上传',
      }
    }
  }