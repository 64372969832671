<template>
    <div class="nav-header">
        <!-- 折叠按钮 -->
        <div class="collapse-btn" @click="collapseChage">
            <i v-if="!collapse" class="el-icon-s-fold"></i>
            <i v-else class="el-icon-s-unfold"></i>
        </div>
        <div class="logo">
            <router-link to='/'><img src="/image/logo.svg" alt=""></router-link>
        </div>
        
        <div class="header-right">

            <div class="header-user-con">

                <div class="header-search">
                    <el-input
                        :placeholder="$t('data.header.searchEcert')"
                        suffix-icon="el-icon-search"
                        size="small"
                        @change="searchResult"
                        v-model="headerSearch">
                    </el-input>
                </div>

                <div class="header-button" v-if="$store.getters.isAdminAuth">
                    <el-button @click="toEcertEdit" class="el-button--green" icon="el-icon-tickets" size="small" round>{{ $t('data.header.createNewEcert') }}</el-button>
                </div>


                <el-dropdown style="margin-left: 15px;" trigger="click" @command="handleLanguage">
                    <span class="el-dropdown-link">
                        {{ language }}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <el-dropdown-item command="cn">简体</el-dropdown-item>
                            <el-dropdown-item command="hk">繁體</el-dropdown-item>
                            <el-dropdown-item command="en">EN</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>



                <!-- 用户头像 -->
                <div class="user-avator">
                    <img :src="userAvator" />
                </div>

                <!-- 用户名下拉菜单 -->
                <el-dropdown class="user-name" trigger="click" @command="handleCommand">
                    <span class="el-dropdown-link">
                        {{username}}
                        <i class="el-icon-caret-bottom"></i>
                    </span>
                    <template #dropdown>
                        <el-dropdown-menu>
                            <!-- <a href="https://github.com/lin-xin/vue-manage-system" target="_blank">
                                <el-dropdown-item>项目仓库</el-dropdown-item>
                            </a> -->
                            <el-dropdown-item divided command="organization"  v-if="$store.getters.isAdminAuth">{{ $t('data.header.setting') }}</el-dropdown-item>
                            <el-dropdown-item divided command="download" icon="el-icon-download">{{ $t('data.header.download') }}</el-dropdown-item>
                            <el-dropdown-item divided command="loginout">{{ $t('data.header.logOut') }}</el-dropdown-item>
                        </el-dropdown-menu>
                    </template>
                </el-dropdown>

                

            </div>
        </div>
    </div>
</template>

<script>
import { userLogut,getBase } from '../../api/index'
export default {
    data() {
        return {
            fullscreen: false,
            name: "",
            headerSearch: ''
        };
    },
    computed: {
        username() {
            let username = localStorage.getItem("ms_username");
            return username ? username : this.name;
        },
        collapse() {
            return this.$store.state.collapse;
        },
        language(){
            let lang = localStorage.getItem("language");
            if(lang === 'cn'){
                return '简体';
            } else if (lang === 'hk'){
                return '繁體'
            } else if (lang === 'en'){
                return 'EN'
            } else {
                return 'EN'
            }
        },
        userAvator(){
            let userAvator
            if(localStorage.getItem("userInfo")){
                userAvator = JSON.parse(localStorage.getItem("userInfo")).info.avatar;
            } else {
                userAvator = '@/assets/img/user.jpg';
            }
            return userAvator
        }
    },
    methods: {
        // 用户名下拉菜单选择事件
        handleCommand(command) {
            if (command == "loginout") {
                userLogut({}).then( res => {
                    localStorage.removeItem("ms_username");
                    localStorage.removeItem('userInfo');
                    this.$router.push("/login");
                })
            } else if(command == "organization"){
                this.$router.push("/account/organization");
            } else if(command == "download"){
                getBase({}).then( res => {
                    window.open(res.data.user_manual);
                })
            }
        },
        toEcertEdit(){
            window.location.href = '/ecert/edit';
        },
        // 侧边栏折叠
        collapseChage() {
            this.$store.commit("hadndleCollapse", !this.collapse);
        },
        handleLanguage(command){
            localStorage.setItem("language", command);
            this.$router.go(0);
        },
        searchResult(){
            if(this.headerSearch){
                this.$router.push("/ecert/issued-certs?search="+this.headerSearch)
            }
        }
    },
    mounted() {
        if (document.body.clientWidth < 1500) {
            this.collapseChage();
        }
    }
};
</script>

<style  scoped>
.nav-header {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 70px;
    font-size: 22px;
    color: #fff;
    /* background-color: #6222EE; */
     background-image: linear-gradient(to right, #224486, #224486, #008FD7);
}
.collapse-btn {
    float: left;
    padding: 0 21px;
    cursor: pointer;
    line-height: 70px;
}
.nav-header .logo {
    float: left;
    width: 150px;
    max-height: 70px;
}
.nav-header .logo img{
    width: 100px;
    height: auto;
}
.header-button{
    height: 70px;
    display: inline-flex;
    align-items: center;
}

.header-right {
    float: right;
    padding-right: 50px;
}
.header-user-con {
    display: flex;
    height: 70px;
    align-items: center;
}
.btn-fullscreen {
    transform: rotate(45deg);
    margin-right: 5px;
    font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
    position: relative;
    width: 30px;
    height: 30px;
    text-align: center;
    border-radius: 15px;
    cursor: pointer;
}
.btn-bell-badge {
    position: absolute;
    right: 0;
    top: -2px;
    width: 8px;
    height: 8px;
    border-radius: 4px;
    background: #f56c6c;
    color: #fff;
}
.btn-bell .el-icon-bell {
    color: #fff;
}
.user-name {
    margin-left: 10px;
}
.user-avator {
    margin-right: 10px;
    margin-left: 20px;
}
.user-avator img {
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.el-dropdown-link {
    color: #fff;
    cursor: pointer;
}
.el-dropdown-menu__item {
    text-align: center;
}
.header-search{
    margin-right: 15px;
}



</style>
