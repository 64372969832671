import { createStore } from 'vuex'

export default createStore({
  state: {
    collapse: false,
    userAuth:  JSON.parse(localStorage.getItem("userInfo")) ? JSON.parse(localStorage.getItem("userInfo")).info.role : '',
    notificationCount: null
  },
  mutations: {
    // 侧边栏折叠
    hadndleCollapse(state, data) {
      state.collapse = data;
    },
    updateAuth(state, data){
      state.userAuth = data;
    },
    hadndleNotification(state, data){
      state.notificationCount = data
    }

  },
  getters:{
    isManagerAuth: state => {
      let auth = JSON.parse(localStorage.getItem("userInfo"));
      if(auth.info){
        if(auth.info.role === 'manager' || auth.info.role === 'admin'){
          return true;
        } else {
          return false;
        }
      }
    },
    isAdminAuth(){
      let auth = JSON.parse(localStorage.getItem("userInfo"));
      if(auth.info){
        if(auth.info.role === 'admin'){
          return true;
        } else {
          return false;
        }
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
