export default {
    data: {
        public: {
            select: 'Select',
            viewMore: 'View More',
            publish: 'Publish',
            share: 'Share',
            duplicate: 'Duplicate',
            update: 'Update',
            confirm: 'Confirm',
            cancel: 'Cancel',
            publishAgain: 'Publish again',
            downloadPdf: 'Download PDF',
            printQRcode: 'Print QRcode',
            printeCert: 'Print eCert',
            emailLink: 'Share Link',
            exportExcel: 'Export to excel',
            back: 'Back',
            reset: 'Reset',
            save: 'Save',
            remove: 'remove',
            delete: 'Delete',
            edit: 'Edit',
            submit: 'Submit',
            action: 'Action',
            view: 'View',
            topUp: 'Top-up',
            best: 'Best in',
            sex: {
                male: 'Male',
                female: 'Female'
            },
            orderCompleted: 'Order completed',
            success: 'Success',
            error: 'Error',
            agreeDisclaimer: 'Agree to the above disclaimer',
            selectExpiredInfo: 'Select "Expired" the eCert status will never be modified',
        },
        status: {
            status: 'Status',
            valid: 'Valid',
            expired: 'Expired',
            cancel: 'Cancel',
            change: 'Change',
            amended: 'Amended',
            remark: 'Remark',
            isStatusDesc: 'Warning：Once you change the status from "Valid" to other options, no redo can be made'
        },
        finalStatus:{
            requested: 'Requested',
            deleted: 'Deleted',
            approved: 'Approved',
            accepted: 'Accepted'
        },
        approvalStatus:{
            pending: 'Pending',
            approved: 'Approved',
            disapproved: 'Disapproved'
        },
        role: {
            Manager: 'Manager',
            Officer: 'Officer',
            SuperAdmin: 'SuperAdmin'
        },
        print: {
            title: 'Print',
            printPreview: 'Print Preview',
            certTitle: 'Cert title'
        },
        menu:{
          dashboard: 'Dashboard',
          ecert: 'eCert',
          ecertChild: {
              list: 'Cert List',
              group: 'Cert Group',
              approval: 'Cert Approval'
          },
          account: 'Account',
          accountChild: {
              user: 'User',
              authority: 'Branch'
          },
          credits: 'Credits',
          creditsChild: {
            credits: 'Credits',
            topUp: 'Top-up'
          },
          notification: 'Notification'
        },
        header:{
            searchEcert: 'Search eCert',
            createNewEcert: 'Create New eCert',
            setting: 'Organization Setting',
            download: 'Download Manual',
            logOut: 'Log out'
        },
        chart: {
            branchIssueReport: 'Branch issue Report',
            bestIssueCertificate: 'Best Issue Certificate',
            yearlyIssues: 'Yearly Issues(last 12 months)',
            numberIssues: 'Number of issues',
        },
        login:{
          signIn: 'Sign In',
          newUser: 'New user',
          createAccount: 'Create an account',
          userName: 'username',
          password: 'password',
          keepSigned: 'Keep me signed In',
          forgetPassword: 'Forget password',
          contact: 'Please contact our team at cs&#64;ecert.app'
        },
        register:{
            registrationSystem: 'eCert Registration System',
            organizationInformation: 'Organization information',
            eCertOrganizationSetting: 'eCert Organization Setting',
            organizationName: 'Organization Name',
            organizationAddress: 'Organization address',
            country: 'Country',
            contactNumber: 'Contact Number',
            email: 'Email',
            website: 'Website',
            organisationType: 'Organisation type',
            oneContactPerson: '1st Contact person',
            twoContactPerson: '2nd Contact person',
            organizationLogo: 'Organization Logo',
            campusPicture: 'Organization Picture',
            uploadPicture: 'Upload Picture',
            registrations: 'Registrations/BR/Licensing Documents(Optional)',
            addFile: 'Add File',
            about: 'About Us',
            branchInformation: 'Headquarters will be counted in the first branch (Must fill in)',
            branch: 'Branch',
            branchName: 'Branch name',
            branchAddress: 'Branch address',
            addBranch: 'Add Branch',
            submit: 'Done'
        },
        dashboard: {
            remainingCredits: 'Remaining Credits',
            issuedPresent: 'Can be issued at present',
            pendingRequests: 'Pending eCert requests',
            buyCreditsIssues: 'To Buy more Credits for issues',
            topUp: 'Top Up',
            days: '7 days',
            month: '1 month',
            year: '1 year',
            exportExcel: 'Export To Excel',
            userNumber: 'Cumulative number of issuing user accounts',
            issuedNumber: 'Cumulative number of issued eCert',
            usedCredit: 'Cumulative used credit',
            viewNumber: 'Cumulative scan to view the number of eCert',
            pendingApplications: 'Pending Applications',
            viewAllApplications: 'View All applications',
            notifications: 'Notifications',
            recentIssuedCerts: 'Recent Issued Certs',
            viewAllIssuedCerts: 'View All Issued Certs'
        },
        eCert: {
            branchName: 'Branch Name',
            remainingIssues: 'Remaining Issues',
            eCertTopic: 'eCert Topic',
            createTime: 'Create Time',
            completedApplication: 'Completed Application',
            duplicateDesc1: '“Duplicate" File cannot be deleted! ',
            duplicateDesc2: 'You can change the content inside the file.',
            requesteCertQRcode: 'Request eCert QRcode',
            scanQRCode: 'Scan the QRCode to apply the eCert in eCertApp',
            certTitle: 'Cert title',
            printQRCode: 'Download QR Code',
            copyLink: 'Anyone with the link can collect the eCert in eCertApp',
            copyLinkBtn: 'Copy Link'
        },
        template: {
            certificateDetail: 'Certificate Detail',
            eCertTopic: 'eCert Topic',
            group: 'Group',
            eCertCatagory: 'eCert Catagory',
            createNewGroupHere: 'Create New Group here',
            approveMember: 'Pre-approve Member',
            searchUserEmail: 'Search User by email',
            uploadSpreadsheet: 'Upload spreadsheet',
            createeCert: 'Create eCert',
            downloadDesign: 'Download design specifications',
            certificateContent: 'Certificate Content',
            uploadDesign: 'Upload your design',
            uploadLeftLogo: 'Upload Left Logo',
            uploadMiddleLogo: 'Upload Middle Logo',
            defaultBackground: 'or Choose default background',
            left: 'Left',
            center: 'Center',
            right: 'Right',
            certificateCourse: 'Certificate Course',
            certificateName: 'Certificate Name',
            certificateSubTitle: 'Certificate Sub-Title',
            showPrintedName: 'Show Printed Name',
            showDateBirth: 'Show Date of birth',
            showNationality: 'Show Nationality',
            certificateDescription1: 'This is a sample text. Insert your desired text here.This is a sample text. Insert your desired text here. on the date of',
            eCertIssueDate: 'eCert Issue Date ( ie. the date print on the Cert ) ',
            certificateDescription2: 'This is a sample text. Insert your desired text here. Expiry date on',
            eCertExpiryDate: 'eCert Expiry Date ( Optional )',
            certificateDescription3: 'This is a sample text. Insert your desired text here.This is a sample text. Insert your desired text here.',
            leftSign: 'Left Sign / Chop',
            centerSign: 'Center Sign / Chop',
            rightSign: 'Right Sign / Chop',
            name: 'Name here',
            title: 'Title',
            organization: 'Organization',
            previewPrint: 'Preview | Print',
            numberIssuesRemainBranch: 'Number of issues remain in branch',
            issues: 'issues',
            preApprovedeCert: 'Pre-approved eCert by (email)',
            acceptPublicApplicant: 'Accept public applicant by (QR code and Link)',
            issueeCertsNow: 'Issue eCerts now',
            publishText1: 'Before publish! Please use the print function to confirm your design and text.',
            publishText2: 'After Publish, the value of the remaining issues cannot be return or refund.',
            confirmIssuePopup: 'Confirm issue Popup',
            ConfirmIssue: 'Confirm issue',
            certsNow: 'certs now',
            table: {
                photo: 'Photo',
                userName: 'Username',
                eMail: 'eMail',
                actions: 'Actions',
                searchUserEmail: 'Search User by email'
            }
        },
        eCertDetail: {
            topic: 'Topic',
            group: 'Group',
            createTime: 'Create Time',
            pendingApplication: 'Pending Application',
            approvedApplication: 'Approved  Application',
            eCertIssueDate: 'eCert issue date',
            totalNumberIssues: 'Total number of Issues',
            scanQRCode: 'Scan QRCode to obtain the Cert in eCertApp',
            eCertTitle: 'eCert title',
            table: {
                photo: 'Photo',
                applicantName: 'Applicant Name',
                eCertNumber: 'eCert Number',
                issueDate: 'Issue Date',
                campusBranch: 'Campus/Branch',
                approval: 'Approval',
                approvedTime: 'Approved Time',
                approvedAccount: 'Approved account'
            }
        },
        group: {
            createNewGroup: 'Create New Group',
            isDeleteText: 'Are you sure you want to delete it',
            eCertGroup: 'eCert Group',
            groupName: 'Group Name',
            enterGroupName: 'Enter Group Name',
            groupNumber: 'Group Number',
            enterGroupNumber: 'Enter Group Number',
            table: {
                groupNumber: 'Group Number',
                groupName: 'Group Name',
                createDate: 'Create Date',
                campusBranch: 'Campus / Branch',
                handleAccount: 'Handle account',
                name: 'Name',
                designs: 'Designs',
                approvedCerts: 'Approved Certs',
                action: 'Action'
            }
        },
        approval: {
            waitingApprove: 'Waiting to approve',
            table:{
                photo: 'Photo',
                applicantName: 'Applicant Name',
                eCertNumber: 'eCert Number',
                issueDate: 'Issue Date',
                campusBranch: 'Campus/Branch',
                requestedTime: 'Requested Time',
                certTopic: 'Cert Topic',
                userName: 'User Name'
            }
        },
        account: {
            addUse: 'Add Use',
            userInfo: 'User Info',
            id: 'ID',
            username: 'Username',
            name: 'Name',
            icon: 'Icon',
            password: 'Password',
            role: 'Role',
            branchName: 'Branch Name',
            createTime: 'Create Time',
            updateTime: 'Update Time',
            table: {
                photo: 'Photo',
                id: 'ID',
                userName: 'User Name',
                name: 'Name',
                role: 'Role',
                branchName: 'Branch Name'
            }
        },
        branch: {
            addBranch: 'Add Branch',
            isDeleteText: 'Are you sure you want to delete it',
            branchInformation: 'Branch information',
            table: {
                BranchName: 'Branch Name',
                BranchAddress: 'Branch Address',
                Country: 'Country',
                ContactNumber: 'Contact Number',
                ContactPerson: 'Contact Person',
                eMail: 'eMail'
            }
        },
        credits: {
            organizationName: 'Organization name',
            organizationId: 'Organization ID',
            remainingCredits: 'Remaining Credits',
            issue: 'issue',
            issues: 'Issues',
            credits: 'Credits',
            accounts: 'Accounts',
            assignCredits: 'Assign Credits',
            issuesDisturbution: 'Issues Disturbution',
            eCertIssueReport: 'eCert issue Report',
            pricing: 'Pricing',
            price: 'Price',
            usd: 'USD',
            paymentHistory: 'Payment History',
            creditRedeemHistory: 'Credit Redeem History',
            invoice: 'Invoice',
            proceedPayment: 'Proceed to payment',
            paymentStatus: {
                waiting: 'Waiting',
                successful: 'Successful',
                failure: 'Failure'
            },
            table:{
                date: 'Date',
                time: 'Time',
                package: 'Package',
                amount: 'Amount',
                status: 'Status',
                branchName: 'Branch Name',
                account: 'Account',
                certName: 'Cert Name',
                creditRedeemed: 'Credit Redeemed',
                publishedCerts: 'Published Certs'
            }
        },
        invoice: {
            invoice: 'INVOICE',
            orderDate: 'Order Date',
            invoiceNumber: 'Invoice number',
            paymentMethod: 'Payment Method',
            name: 'Name',
            organisationAddress: 'Organisation Address',
            email: 'Email',
            item: 'ITEM',
            total: 'TOTAL (US$)',
            subtotal: 'Subtotal',
            tax: 'Tax',
            grandTotal: 'GRAND TOTAL',
            questions: 'Questions',
            emailUsAt: 'Email us at'
        },
        notification: {
            title: 'Notification',
            event: 'Event',
            time: 'Time',
            details: 'Details'
        },
        issuedCerts: {
            table: {
                photo: 'Photo',
                applicantName: 'Applicant Name',
                eCertNumber: 'eCert Number',
                certTopic: 'Cert Topic',
                certIssueDate: 'Cert Issue Date',
                campusBranch: 'Campus / Branch',
                status: 'Status',
                approvedTime: 'Approved Time',
                approvedBy: 'Approved By'
            }
        },
        applicationDetail: {
            title: 'Application Detail',
            topic: 'Topic',
            group: 'Group',
            eCertNo: 'eCert No',
            timeRequested: 'Time Requested',
            approvalTime: 'Approval Time',
            previewEcert: 'Preview eCert',
            uploadDocument: 'Upload document',
            applicantId: 'Applicant ID',
            informationClaimed: 'Information claimed for verification',
            firstName: 'First Name',
            lastName: 'Last Name',
            namePrinted: 'Name Printed on Certificate',
            email: 'Email',
            mobile: 'Mobile',
            sex: 'Sex',
            nationality: 'Nationality',
            dateBirth: 'Date of Birth',
            address: 'Address',
            action: 'Action',
            scanQRCode: 'Scan QRCode to verify the eCert in eCertApp',
            eCertTitle: 'ECert title'
        }
    },
    el:{
        pagination: {
            goto: 'Go to',
            pagesize: ' / page',
            total: 'Total {total}',
            pageClassifier: '',
        },
        popconfirm: {
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
        },
        select: {
            loading: 'Loading',
            noMatch: 'No matching data',
            noData: 'No data',
            placeholder: 'Select',
        },
        datepicker: {
            now: 'Now',
            today: 'Today',
            cancel: 'Cancel',
            clear: 'Clear',
            confirm: 'OK',
            selectDate: 'Select date',
            selectTime: 'Select time',
            startDate: 'Start Date',
            startTime: 'Start Time',
            endDate: 'End Date',
            endTime: 'End Time',
            prevYear: 'Previous Year',
            nextYear: 'Next Year',
            prevMonth: 'Previous Month',
            nextMonth: 'Next Month',
            year: '',
            month1: 'January',
            month2: 'February',
            month3: 'March',
            month4: 'April',
            month5: 'May',
            month6: 'June',
            month7: 'July',
            month8: 'August',
            month9: 'September',
            month10: 'October',
            month11: 'November',
            month12: 'December',
            week: 'week',
            weeks: {
                sun: 'Sun',
                mon: 'Mon',
                tue: 'Tue',
                wed: 'Wed',
                thu: 'Thu',
                fri: 'Fri',
                sat: 'Sat',
            },
            months: {
                jan: 'Jan',
                feb: 'Feb',
                mar: 'Mar',
                apr: 'Apr',
                may: 'May',
                jun: 'Jun',
                jul: 'Jul',
                aug: 'Aug',
                sep: 'Sep',
                oct: 'Oct',
                nov: 'Nov',
                dec: 'Dec',
            },
            monthNaN: ''
        },
        table: {
            emptyText: 'No Data',
            confirmFilter: 'Confirm',
            resetFilter: 'Reset',
            clearFilter: 'All',
            sumText: 'Sum',
        },
        upload: {
            deleteTip: 'press delete to remove',
            delete: 'Delete',
            preview: 'Preview',
            continue: 'Continue',
        }
    }
        

  }