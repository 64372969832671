<template>
    <div class="nav-sidebar">
        <el-menu
            class="sidebar-el-menu"
            :default-active="onRoutes"
            :collapse="collapse"
            background-color="#224486"
            text-color="#bfcbd9"
            active-text-color="#1795DA"
            unique-opened
            router
        >
            <template v-for="item in items">
                <template v-if="item.subs">   
                    <el-submenu :index="item.index" :key="item.index">
                        <template #title>
                            <i :class="item.icon"></i>
                            <span>{{ item.title }}</span>
                        </template>
                        <template v-for="subItem in item.subs">
                            <el-submenu
                                v-if="subItem.subs"
                                :index="subItem.index"
                                :key="subItem.index"
                            >
                                <template #title>{{ subItem.title }}</template>
                                <el-menu-item
                                    v-for="(threeItem, i) in subItem.subs"
                                    :key="i"
                                    :index="threeItem.index"
                                >{{ threeItem.title }}</el-menu-item>
                            </el-submenu>
                            <el-menu-item
                                v-else
                                :index="subItem.index"
                                :key="subItem.index"
                            >{{ subItem.title }}</el-menu-item>
                        </template>
                    </el-submenu>
                </template>
                <template v-else>
                    <el-menu-item :index="item.index" :key="item.index">
                        <i :class="item.icon"></i>
                        <template #title>
                            {{ item.title }}
                            <el-badge v-if="item.index === '/notification/list' && unreadCount !== 0" class="mark nav-mark" :value="unreadCount" type="danger" />
                        </template>
                        
                    </el-menu-item>
                </template>
            </template>
        </el-menu>
    </div>
</template>

<script>
import { notificationsUnreadCount } from '../../api/index'
export default {
    name: 'nav_sidebar',
    data() {
        return {
            items: [
                {
                    icon: "el-icon-s-data",
                    index: "/dashboard",
                    title: this.$t('data.menu.dashboard')
                },
                {
                    icon: "el-icon-tickets",
                    index: "ecert",
                    title: this.$t('data.menu.ecert'),
                    subs: [
                        {
                            index: "/ecert/list",
                            title: this.$t('data.menu.ecertChild.list')
                        },
                        {
                            index: "/ecert/group",
                            title: this.$t('data.menu.ecertChild.group')
                        },
                        {
                            index: "/ecert/approval",
                            title: this.$t('data.menu.ecertChild.approval')
                        }
                    ]
                },

                {
                    icon: "el-icon-user",
                    index: "account",
                    title: this.$t('data.menu.account'),
                    subs:[
                        {
                            index: '/account/user',
                            title: this.$t('data.menu.accountChild.user')
                        },{
                            index: '/account/authority',
                            title: this.$t('data.menu.accountChild.authority')
                        }
                    ]
                },
                {
                    icon: "el-icon-coin",
                    index: "credits",
                    title: this.$t('data.menu.credits'),
                    subs:[
                        {
                            index: '/credits/main',
                            title: this.$t('data.menu.creditsChild.credits')
                        },{
                            index: '/credits/pricing',
                            title: this.$t('data.menu.creditsChild.topUp')
                        }
                    ]
                },
                // {
                //     icon: "el-icon-coin",
                //     index: "/credits/main",
                //     title: this.$t('data.menu.credits')
                // },
                {
                    icon: "el-icon-bell",
                    index: "/notification/list",
                    title: this.$t('data.menu.notification')
                }
                // {
                //     icon: "el-icon-circle-close",
                //     index: "7",
                //     title: "错误处理",
                //     subs: [
                //         {
                //             index: "permission",
                //             title: "权限测试"
                //         },
                //         {
                //             index: "404",
                //             title: "404页面"
                //         }
                //     ]
                // }
            ]
        };
    },
    computed: {
        onRoutes() {
            return this.$route.path.replace("", "");
        },
        collapse(){
            return this.$store.state.collapse
        },
        unreadCount(){
            return this.$store.state.notificationCount
        }
    },
    created(){
    },
    mounted(){
        this.initMenu();
        this.initUnreadCount();
    },
    methods: {
        initMenu(){
            for(let i=0; i<this.items.length; i++){
                const auth = this.$store.state.userAuth;
                if(this.items[i].index === "account" && auth !== "admin"){
                    for(let s=0; s<this.items[i].subs.length; s++){
                        if(this.items[i].subs[s].index === "/account/authority" && auth !== "admin"){
                            this.items[i].subs.splice(s, 1);
                            s--;
                        } else if(this.items[i].subs[s].index === "/account/user" && auth !== "admin" && auth !== "manager"){
                            this.items.splice(i, 1);
                            i--;
                        }
                    }
                } else if(this.items[i].index === "credits"){
                    for(let s=0; s<this.items[i].subs.length; s++){
                        if(this.items[i].subs[s].index === "/credits/pricing" && auth !== "admin"){
                            this.items[i].subs.splice(s, 1);
                            s--;
                        } else if(this.items[i].subs[s].index === "/credits/main" && auth !== "admin" && auth !== "manager"){
                            this.items.splice(i, 1);
                            i--;
                        }
                    }
                } else if(this.items[i].index === "ecert"){
                    for(let s=0; s<this.items[i].subs.length; s++){
                        if(this.items[i].subs[s].index === "/ecert/group" && auth !== "admin" && auth !== "manager"){
                            this.items[i].subs.splice(s, 1);
                            s--;
                        }
                    }
                }
            }
        },
        initUnreadCount(){
            notificationsUnreadCount({}).then( res => {
                this.$store.commit("hadndleNotification", res.data.count);
            }) 
        }
    }
};
</script>
<style scoped>
.nav-sidebar {
    display: block;
    position: absolute;
    left: 0;
    top: 70px;
    bottom: 0;
    overflow-y: scroll;
}
.nav-sidebar::-webkit-scrollbar {
    width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
    width: 250px;
}
.nav-sidebar > ul {
    height: 100%;
}
.el-submenu .el-menu-item{
    padding-left: 49px !important;
}
.nav-mark{
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    font-size: 12px;
}
</style>