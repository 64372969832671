import { createApp } from 'vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import Print from 'vue3-print-nb'
import * as echarts from 'echarts'
import './element-variables.scss'
import '../src/assets/icon/font_icon/iconfont.css'
import App from './App.vue'
import i18n from './language/i18n'

const app = createApp(App).use(router).use(store).use(ElementPlus, {i18n: i18n.global.t}).use(i18n).use(Print).mount('#app')

app.echarts=echarts

